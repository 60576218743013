import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent} from "./login.component";
import {SignupComponent} from "./signup/signup.component";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {MdbFormsModule} from "mdb-angular-ui-kit/forms";
import {ToastrModule} from "ngx-toastr";



@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        ToastrModule.forRoot(),
        MdbFormsModule
    ]
})
export class LoginModule { }
