<section class="vh-100" style="background-color: #E4F3EB;">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card shadow-2-strong" style="border-radius: 1rem;">
          <div [formGroup]="signupForm" class="card-body p-5 text-center">
            <span><img src="favicon.png" width="15%" height="15%"/><h3 class="mt-1 mb-5">Create Account</h3></span>

            <mdb-form-control class="mb-4">
              <input required mdbInput type="email" id="typeEmailX-2" class="form-control form-control-lg"
                     formControlName="email"/>
              <label mdbLabel class="form-label" for="typeEmailX-2">Email</label>
            </mdb-form-control>

            <mdb-form-control class="mb-4">
              <input mdbInput type="password" id="typePasswordX-2" class="form-control form-control-lg"
                     formControlName="password"/>
              <label mdbLabel class="form-label" for="typePasswordX-2">Password</label>
            </mdb-form-control>

            <mdb-form-control class="mb-4">
              <input mdbLabel type="password" id="typePasswordX-3" class="form-control form-control-lg"
                     formControlName="confirmPassword"/>
              <label mdbInput class="form-label" for="typePasswordX-3">Confirm Password</label>
            </mdb-form-control>

            <mdb-form-control class="mb-4">
              <input mdbLabel type="password" id="mobileNumber" class="form-control form-control-lg"
                     formControlName="mobileNumber"/>
              <label mdbInput class="form-label" for="mobileNumber">Mobile Number</label>
            </mdb-form-control>

            <button [disabled]="!this.signupForm.valid && !this.isPasswordValid()"
                    class="btn btn-primary btn-lg btn-block" (click)="signUp()">Create Account
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
