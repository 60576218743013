import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signupForm = this.fb.group({
    email: new FormControl('', [Validators.email, Validators.required]),
    mobileNumber: new FormControl('', [Validators.required, Validators.pattern('\g[0-9]')]),
    password: new FormControl('', [Validators.required, Validators.minLength(6),
      Validators.maxLength(18)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6),
      Validators.maxLength(18)])
  })

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {

  }

  signUp() {
    console.log('SignupComponent ', this.signupForm.valid);
  }

  isPasswordValid() {
    if (this.signupForm.valid) {
      if (this.signupForm.get('password')?.value === this.signupForm.get('confirmPassword')?.value) {
        return true;
      }
    }
    return false;
  }
}
