import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = this.fb.group({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)])
  });

  constructor(private router: Router,
              private toastrService: ToastrService,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
  }

  goToSignup() {
    this.router.navigate(['signup'])
  }

  login() {
    if (this.loginForm.valid) {
      if (this.loginForm.get('email')?.value === 'surajgautam2k52@gmail.com'
        && this.loginForm.get('password')?.value === 'admin123')
      {
        this.toastrService.success('Logged In Successful', 'Success');
      }
    }
  }
}
