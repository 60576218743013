<header>
  <!-- Navbar -->
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img
          src="../../favicon.png"
          class="me-2"
          height="20"
          alt=""
          loading="lazy"
        />
        <small>NEPSE Portfolio</small>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        (click)="backgroundImageExample.toggle()"
        aria-controls="backgroundImageExample"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars"></i>
      </button>
      <div
        mdbCollapse
        #backgroundImageExample="mdbCollapse"
        class="collapse navbar-collapse"
        id="backgroundImageExample"
      >
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item active">
            <a class="nav-link" aria-current="page" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#features">Features</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#footer">About</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- Navbar -->

  <!-- Background image -->
  <div
    class="p-5 text-center bg-image"
    style="
      background-image: url('../../assets/background.jpg');
      height: 400px;
    "
  >
    <div class="mask" style="background-color: rgba(0, 0, 0, 0.5);">
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="text-white">
          <h1 class="mb-3">Ready to grow your portfolio <i class="fas fa-chart-line fa-1x"></i></h1>
          <a class="btn btn-outline-light btn-lg" [routerLink]="['/login']" role="button"
          >Get Started</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- Background image -->
</header>
<main class="my-5">
  <div class="container">
    <section id="features" class="text-center">
      <h3 class="mb-5">We've got your back</h3>
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-12">
          <i class="fas fa-rocket fa-3x text-dark"></i>
          <h5 class="my-4">Blazing Fast</h5>
          <p>Our product aims to provide our customers with a smooth and fast experience so that they can accomplish their goals.</p>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <i class="fas fa-clock fa-3x text-success"></i>
          <h5 class="my-4">Real Time Data</h5>
          <p>You can get access to all your data and compare with the live stock prices real time.</p>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <i class="fas fa-lock fa-3x text-primary"></i>
          <h5 class="my-4">Secure</h5>
          <p>One of our missions is to provide services securely so that our customers can use it without second thoughts.</p>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <i class="fas fa-mobile-alt fa-3x"></i>
          <h5 class="my-4">Responsive</h5>
          <p>Our design is responsive. It means our clients can access our platform on any devices.</p>
        </div>
      </div>
    </section>
    <hr/>
    <section id="newsletter" class="m1-2 text-center">
      <h3>Subscribe to our newsletter</h3>
          <div class="d-flex flex-column justify-content-center">
            <div class="p-2">
              <label class="ml-2 form-label" for="form1">Email:</label>&nbsp;
              <input style="width: 50%;" type="text" id="form1" required/>
            </div>
          </div>
          <button type="button" class="mt-2 btn btn-primary">Subscribe</button>
    </section>
  </div>
</main>
<!-- Footer -->
<footer id="footer" class="text-center text-lg-start bg-white text-black" style="background-color: white">
  <!-- Section: Social media -->
  <section
    class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
  >
    <!-- Left -->
    <div class="me-5 d-none d-lg-block">
      <span>Get connected with us on social networks:</span>
    </div>
    <!-- Left -->

    <!-- Right -->
    <div>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-facebook-f"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-twitter"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-google"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-instagram"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-linkedin"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-github"></i>
      </a>
    </div>
    <!-- Right -->
  </section>
  <!-- Section: Social media -->

  <!-- Section: Links  -->
  <section class="">
    <div class="container text-center text-md-start mt-5">
      <!-- Grid row -->
      <div class="row mt-3">
        <!-- Grid column -->
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          <!-- Content -->
          <h6 class="text-uppercase mb-4">
            <i class="fas fa-gem me-3"></i>NEPSE Portfolio
          </h6>
          <p>
            NEPSE Portfolio is an online web application developed with the sole purpose to help Nepal Stock Exchange
            Users to view, manage and organize their stock portfolio.
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <!-- Links -->
          <h6 class="text-uppercase mb-4">
            Useful links
          </h6>
          <p>
            <a href="#!" class="text-reset">Dashboard</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Settings</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Orders</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Help</a>
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <!-- Links -->
          <h6 class="text-uppercase mb-4">
            Contact
          </h6>
          <p><i class="fas fa-home me-3"></i> New York, NY 10012, US</p>
          <p>
            <i class="fas fa-envelope me-3"></i>
            info@nepseportfolio.com
          </p>
          <p><i class="fas fa-phone me-3"></i> + 01 234 567 88</p>
          <p><i class="fas fa-print me-3"></i> + 01 234 567 89</p>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </div>
  </section>
  <!-- Section: Links  -->

  <!-- Copyright -->
  <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
    © 2022 Copyright:
    <a class="text-reset" href="https://mdbootstrap.com/">NEPSE Portfolio</a>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
